.time {
  position: relative;
}

.time > .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: white; */
  z-index: 100;
}

.diff {
  height: 550px !important;
  margin-bottom: 20px !important;
}

.option:hover {
  color: #000 !important;
}

.copyRestrict {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
