#login {
    height: unset !important;
}

#login>.container {
    margin-top: 187px;
    margin-bottom: 58px;
}

.password {
    margin-top: 10px;
    background-color: rgba(168, 243, 174, 0.699);
    color: #fa668b;
    /* padding: 10px; */
    border-radius: 5px;
    outline: none;
    border: none;
    height: 30px;
    width: 125px;
    font-size: 10;
}

