.single {
    margin: 10px;
    padding: 15px
}

.optionNumber {
    border-radius: 30px;
    background: #fff;
    padding: 10px;
    margin-right: 10px;
}

.option {
    border-radius: 20px;
    background-color: rgb(255, 217, 0);
}

.green {
    background-color: rgb(6, 139, 6) !important;
}

.red {
    background-color: red !important;
}