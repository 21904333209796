.current a {
    color: #00a600 !important;
}

/* CSS */
.button-72 {
    align-items: center;
    background-color: initial;
    background-image: linear-gradient(rgba(152, 201, 132, 0.84), rgba(36, 91, 31, 0.84) 50%);
    border-radius: 42px;
    border-width: 0;
    box-shadow: rgba(36, 91, 31, 0.24) 0 2px 2px, rgba(134, 201, 132, 0.4) 0 8px 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .04em;
    line-height: 16px;
    margin: 0 auto;
    padding: 18px 18px;
    text-align: center;
    text-decoration: none;
    text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px, rgba(255, 255, 255, 0.2) 0 0 12px, rgba(43, 91, 31, 0.6) 1px 1px 4px, rgba(31, 91, 34, 0.32) 4px 4px 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
}

.button-72 a:hover {
    background-image: linear-gradient(#92c984, #1f5b1f 50%);
}

@media (min-width: 768px) {
    .button-72 a {
        font-size: 21px;
        padding: 18px 34px;
    }
}