.img-holder {
  height: 200px;
  width: 200px;
  margin-left: 222px;
}

.verify {
  height: 30px;
  width: 100px;
  margin-left: 60px;
  font-weight: 300;
  border-radius: 10px;
  background-color: #419e7b;
  color: aliceblue;
}

.verify:hover {
  height: 32px;
  width: 110px;
  margin-left: 60px;
  font-weight: 300;
  border-radius: 10px;
  background-color: #da4880;
  color: aliceblue;
  text-align: center;
}

.verified {
  height: 30px;
  width: 100px;
  margin-left: 60px;
  font-weight: 300;
  border-radius: 10px;
  background-color: #419e7b;
  color: rgb(242, 243, 243);
  cursor: not-allowed;
}

.verified:hover {
  height: 32px;
  width: 110px;
  margin-left: 60px;
  font-weight: 300;
  border-radius: 10px;
  background-color: #da4880;
  color: rgb(242, 243, 243);
  cursor: not-allowed;
  text-align: center;
}

.col {
  margin: 50 50 50;
  border: 1px solid;
  border-color: black;
}

.col .box {
  border: 3px solid;
  border-color: #f5eee6;
  color: aliceblue;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  line-height: 30px;
  font-size: 18px;
}

#myFileInput {
  /* Add custom styles to the file input field */
  width: 0;
  height: 0;
  overflow: hidden;
}

.imgleft {
  position: relative;
  height: 200px;
  width: 200px;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: #0d6dfd52;
  color: #fff;
  display: none;
}

.imgleft:hover .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
