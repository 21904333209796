 .fixedTimer {
    position: fixed;
    right: 34px;
    top: 52%;
    z-index: 100;
    transform: translate(-20, -20%);
    background-color: black;
    height: 100px;
    width: 210px;
    opacity: 80%;
    text-align: center;
    border-radius: 10px;
}

 .fixedTimer #time{
    line-height: 50px;
    color:white;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
 
.fixedTimer {
         top: 72%;
         height: 45px;
         width: 100px;
         border-radius: 5px;
         right: 40px;
     }
 .fixedTimer #time{
         line-height: 20px;
         font-size: 16px;
         font-weight: 300;
         top:60%;

    }
}