.showOnlyOnMobile,
.footerOnMobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .display-none-on-phone {
        display: none;
    }

    /* 
    #banner::before {
        height: 89vh !important;
    } */

    .showOnlyOnMobile {
        display: block;
        margin-top: 60px;
    }

    .dontShowOnMobile {
        display: none;
    }

    .footerOnMobile {
        display: block;
    }

    .img .overlay_img {
        position: absolute;
        padding-bottom: 400px;

    }

}


#banner .showOnlyOnMobile a {
    font-size: 18px !important;
    line-height: inherit !important;
    background: none !important;
    padding: inherit !important;
    border-radius: none;
    margin-top: 0 !important;
    color: #004bbb !important;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;

}

.ads {
    border: 2px solid green;
    height: 100%;
}

.my-caption {

    background-color: rgb(122, 40, 203);
    right: unset;
    bottom: 38%;
    padding: 30px;

    display: none;
}



/* @media screen and (max-width: 768px) {
    #banner::before {
        height: 100vh !important;
    }
} */

.caro-img {
    max-height: 700px;
}

.regOnlyOnMobile {
    background: green !important;
    display: none;
}

@media screen and (max-width: 768px) {
    .regOnlyOnMobile {
        display: block;
        width: 150px;
    }

    .caro-img {
        height: 260px;
    }
}

.caro-img {
    border: 3px solid #84397D;
    border-radius: 15px;
}

@media screen and (min-width: 1440px) {

    .caro-img {
        height: 700px;
    }
}